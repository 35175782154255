import { useEffect, useState } from "react";
import "./Auth.css";
import useBackend from "./useBackend";
import { UserKey } from "./App";
import CentralSpinner from "./components/CentralSpinner";
export default function Auth(props: {setUser: (user: UserKey) => void}) {

    const oauthUrl = `http://www.strava.com/oauth/authorize?client_id=123342&response_type=code&redirect_uri=https://origo-backend.onrender.com/strava-callback-web&approval_prompt=force&scope=read,activity:read,activity:read_all,profile:read_all`;

    const { get } = useBackend();

    // show a loading whilst we decide
    const [isCallback, setIsCallback] = useState(true);

    useEffect(() => {

        const loggedInUser = localStorage.getItem("user");

        if (loggedInUser !== null) {

            props.setUser(JSON.parse(loggedInUser));

            return;
        }

        (async() => {
            const urlParams = new URLSearchParams(window.location.search);
            const code = urlParams.get("code");

            if (code === null) {
                setIsCallback(false);
                return;
            }

            const response = await get<UserKey>("/add-user?code=" + code);

            if (response.status !== 200) {
                alert("something went wrong");
                window.history.replaceState(null, '', window.location.pathname);
                setIsCallback(false);
                return;
            }

            props.setUser(response.body);
            window.history.replaceState(null, '', window.location.pathname);
            localStorage.setItem("user", JSON.stringify(response.body));
        })();

       
    }, []);

    if (isCallback) {
        return <CentralSpinner />
    }

    return <div className="Auth">
        <div className="Auth-title">
            <img className="Auth-logo" src="./white-transparent.svg" />
            <img className="Auth-wave" src="./wave.svg" />
        </div>

        <div className="Auth-content">
            
            { //@ts-ignore
                window.navigator.standalone !== false && <a href={oauthUrl}>
                    <img src="btn_strava_connectwith_orange.svg" />
                </a> 
            }

            {
                //@ts-ignore
                window.navigator.standalone === false && <div className="Auth-safari-instructions">
                    Origo works better as an app! Press <img src="./safari-share.png"/> then <div>Add to Home Screen <img src="./safari-add.png"/></div>. See you there!
                </div>
            }
        </div>

        <span className="Auth-copyright">© {new Date(Date.now()).getFullYear()} - Origo Fundraising CIC - All Rights Reserved.</span>
    </div>
}
